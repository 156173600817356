<template>
  <nuxt-link
    @dblclick.stop
    :to="route"
    :class="$style.root"
  >
    <slot />
  </nuxt-link>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const localeRoute = useLocaleRoute()

const props = defineProps({
  to: {
    type: [String, Object]
  },
  locale: {
    type: String,
    optional: true
  }
})

const route = computed(() => {
  return props?.to instanceof Object ? localeRoute(props?.to, props?.locale) : localePath(props?.to, props?.locale)
})
</script>

<style module>
.root {
  composes: reset-focus from global;
}

.root[disabled] {
  pointer-events: none;
}
</style>
